import React from "react"
import Img from "gatsby-image"


const BGImage = ({image, className, children}) => {
    return (
    <div style={{position: "relative"}}>
        <Img fluid={image} style={{position: "absolute", top: "0", left: "0", width: "100%", minHeight: 'calc(100vh - 1px)', zIndex: "-1"}}/>
        <div className={className} style={{position: "absolute", top: "0", height: "100%", width: "100%"}}>{children}</div>
    </div>
    )
    }

export default BGImage;